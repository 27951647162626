import { Link } from "gatsby"
import React from "react"


const CallMe = () => (
  <div className="bg-taxi">  {/* RAL 1015 https://rgb.to/hex/e6d2b5 e6d2b5 */}
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl font-ibm font-bold tracking-tight text-gray-900 md:text-4xl">
        <span className="block text-4xl">Das freundliche Taxiunternehmen in Chieming</span>
        <span className="block text-gray-600">Rufen Sie an. Wir freuen uns. Wir sind bereit.</span>
      </h2>
      <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div className="inline-flex rounded-md shadow">
          <a
            href="tel:0160–813-1826"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent font-bold rounded-md text-white bg-black hover:bg-gray-600"
          >
            {/*  Heroicon name: outline/phone  */}
            <svg
              className="mr-4 flex-shrink-0 h-6 w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
              />
            </svg>
            <span className="ml-3 text-xl">0160 – 813 1826</span>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default CallMe
