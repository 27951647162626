import * as React from "react"
import { Fragment, useState } from "react"
import { BriefcaseIcon, SpeakerphoneIcon, XIcon } from "@heroicons/react/outline"
import { Dialog, Transition } from "@headlessui/react"

export default function Banner() {
  let [isOpen, setIsOpen] = useState(true)

  if (typeof window !== "undefined") {
    let pop_status = localStorage.getItem("pop_status")

    function closeModal() {
      setIsOpen(false)
      localStorage.setItem("pop_status", 1)
    }

    function openModal() {
      setIsOpen(true)
    }

    if (pop_status != 1) {
      return (
        <>
          {setIsOpen}

          <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <Transition
                show={isOpen}
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="p-2 rounded-lg bg-gray-800 shadow-lg sm:p-3">
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                      <span className="flex p-2 rounded-lg bg-taxi">
                        <SpeakerphoneIcon
                          className="h-6 w-6 text-gray-800"
                          aria-hidden="true"
                        />
                      </span>
                      <p className="ml-3 font-medium text-white truncate">
                        <span className="md:hidden">
                          Wir fahren. Auch durch die Krise.
                        </span>
                        <span className="hidden md:inline">
                          Wir fahren. Auch durch die Krise. Und wir kommen Ihnen
                          entgegen! Besondere Preiskonditionen in
                          Pandemiezeiten.
                        </span>
                      </p>
                    </div>
                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                      <button
                        type="button"
                        className="-mr-1 flex p-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={closeModal}
                      >
                        <span className="sr-only">Dismiss</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </>
      )
    } else {
      return null
    }
  } //
  else {
    return null
  }
}
