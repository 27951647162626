import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useState } from "react"
import logo from "../images/taxi-hans-logo.svg"

import { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Navigation() {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1000
        filter: { frontmatter: { main: { eq: 1 } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img className="block lg:hidden h-8 w-auto" src={logo} alt="Taxi Hans Chieming" />
                </Link>
                <Link to="/">
                  <img className="hidden lg:block h-8 w-auto" src={logo} alt="Taxi Hans Chieming" />
                </Link>

                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {allMarkdownRemark.edges.map(page => (
                    <Link
                      key={page.slug}
                      className="border-gray-400 text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium"
                      to={page.node.fields.slug}
                      activeClassName="border-gray-900 text-gray-900"
                      data-label="Tab: Entry"
                      data-category="Site-Wide Custom Events" 
                    >
                      {page.node.frontmatter.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-4 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

              {allMarkdownRemark.edges.map(page => (
                <Link
                  key={page.slug}
                  className="bg-gray-100 border-gray-200 text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  to={page.node.fields.slug}
                  activeClassName="bg-indigo-50 border-indigo-500 text-indigo-700"
                  data-label="Tab: Entry"
                  data-category="Site-Wide Custom Events" 
                >
                  {page.node.frontmatter.title}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
