import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

//import Header from "./header"
import "tailwindcss/tailwind.css"

import Navigation from "./navigation"
import CallMe from "./call-me"
import Banner from "./banner"
import Footer from "./footer"
import Seo from "./seo"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <>
      <Seo />
      {/*<Header siteTitle={data.site.siteMetadata?.title || `Title`} />*/}
      <div className="font-ibm">

        <Navigation />

        <CallMe />
        <main>{children}</main>
        <Banner />
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
